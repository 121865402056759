<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-container>
      <v-card>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <ma-date-picker
            class="pt-2 pr-1"
            v-model="dateFrom"
            label="From"
            past
          />
          <ma-date-picker class="pt-2 pr-1" v-model="dateTo" label="To" past />

          <v-btn
            class="mx-2 mt-3"
            text
            small
            dark
            color="indigo"
            @click="clearFilters()"
          >
            <v-icon dark> mdi-broom </v-icon>clear filters
          </v-btn>
          <v-btn
            class="mx-2 mt-3"
            text
            small
            dark
            color="indigo"
            @click="getStatistics()"
          >
            <v-icon dark> mdi-update </v-icon>Update
          </v-btn>
        </div>
        <template v-if="filterDate != 'clear'">
          <v-layout class="pa-4" column>
            <v-layout column class="text-body-1 text--secondary">
              <div>
                From
                <span class="text-caption">{{ prettyDate(query1.date1) }}</span>
              </div>
              <div>
                To
                <span class="text-caption">{{ prettyDate(query1.date2) }}</span>
              </div>
            </v-layout>
          </v-layout>
          <v-divider></v-divider>
        </template>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="statistics"
          :footer-props="footerProps"
          class="elevation-1"
        >
          <template v-slot:[`header.policy_new`]="{ header }">
            <template v-if="total && total.policy_new">
              {{ `${header.text}(${total.policy_new})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
          <template v-slot:[`item.policy_new`]="{ item }">
            {{ item.policy_new || 0 }}
          </template>
          <template v-slot:[`item.policy_renewall`]="{ item }">
            {{ item.policy_renewall || 0 }}
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total || 0 }}
          </template>
          <template v-slot:[`item.policy_quoted`]="{ item }">
            {{ item.policy_quoted || 0 }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <template>
              <v-icon color="info" @click="showResumeSummary(item)"
                >mdi-eye</v-icon
              >
            </template>
          </template>

          <template v-slot:[`header.total`]="{ header }">
            <template v-if="total && total.total">
              {{ `${header.text}(${total.total})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
          <template v-slot:[`header.policy_quoted`]="{ header }">
            <template v-if="total && total.policy_quoted">
              {{ `${header.text}(${total.policy_quoted})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
          <template v-slot:[`header.policy_renewall`]="{ header }">
            <template v-if="total && total.policy_renewall">
              {{ `${header.text}(${total.policy_renewall})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
        </v-data-table>
        <v-dialog
          v-model="showResume.dialog"
          :overlay="false"
          max-width="700px"
          transition="dialog-transition"
        >
          <v-card>
            <v-container>
              <template>
                <div class="text-center">Quote</div>
                <v-data-table
                  :headers="showResume.headers"
                  :items="showResume.quoted"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:[`item.polizeStatus`]="{ item }">
                    <div
                      class="font-weight-bold"
                      v-bind:style="{ color: colorStatus(item.polizeStatus) }"
                    >
                      {{ getStatusShow(item.polizeStatus) }}
                    </div>
                  </template>
                </v-data-table>
              </template>
              <template>
                <v-divider class="my-2"></v-divider>
                <div class="text-center">Renewals</div>
                <v-data-table
                  :headers="showResume.headers"
                  :items="showResume.renewal"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:[`item.polizeStatus`]="{ item }">
                    <div
                      class="font-weight-bold"
                      v-bind:style="{ color: colorStatus(item.polizeStatus) }"
                    >
                      {{ getStatusShow(item.polizeStatus) }}
                    </div>
                  </template>
                </v-data-table>
              </template>
              <template>
                <v-divider class="my-2"></v-divider>
                <div class="text-center">New</div>
                <v-data-table
                  :headers="showResume.headers"
                  :items="showResume.new"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:[`item.polizeStatus`]="{ item }">
                    <div
                      class="font-weight-bold"
                      v-bind:style="{ color: colorStatus(item.polizeStatus) }"
                    >
                      {{ getStatusShow(item.polizeStatus) }}
                    </div>
                  </template>
                </v-data-table>
              </template>
            </v-container>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { PolicyStatus } from "@/utils/enums/policystatus";
import { mapMutations } from "vuex";
import moment from "moment";
export default {
  components: {
    MaDatePicker,
  },
  data() {
    return {
      dateTo: null,
      dateFrom: null,
      filterDate: "clear",
      headers: [
        {
          text: "Agent",
          align: "start",
          sortable: true,
          value: "fullname",
        },
        {
          text: "Total",
          align: "center",
          sortable: true,
          value: "total",
        },
        {
          text: "Quote",
          align: "center",
          sortable: true,
          value: "policy_quoted",
        },
        /* {
          text: "Conversion %",
          align: "center",
          sortable: true,
          value: "conversion",
        }, */
        {
          text: "Renewals",
          align: "center",
          sortable: true,
          value: "policy_renewall",
        },
        {
          text: "New",
          align: "center",
          sortable: true,
          value: "policy_new",
        },
        {
          text: "",
          align: "center",
          sortable: true,
          value: "actions",
        },
      ],

      showResume: {
        dialog: false,
        quoted: [],
        renewal: [],
        new: [],
        headers: [
          { text: "Name", value: "fullname", sortable: false },
          { text: "Members", value: "member", sortable: false },
          { text: "Status", value: "polizeStatus", sortable: false },
        ],
      },
      query1: {
        date1: "",
        date2: "",
      },
      quoteDate: {
        date1: "",
        date2: "",
      },

      interval: null,
      total: null,
      statistics: [],
      loading: false,
      footerProps: {
        "items-per-page-options": [15, 50, 100, 500],
      },
    };
  },
  watch: {
    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.filterDate = "custom";
        this.getStatistics();
      } else {
        this.filterDate = "clear";
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.filterDate = "custom";
        this.getStatistics();
      } else {
        this.filterDate = "clear";
      }
    },
  },
  methods: {
    ...mapMutations(["mutDisplaylogo"]),

    showResumeSummary(item) {
      this.showResume.quoted = item.clients_quoted ? item.clients_quoted : [];
      this.showResume.renewal = item.clients_renewall
        ? item.clients_renewall
        : [];
      this.showResume.new = item.clients_new ? item.clients_new : [];
      this.showResume.dialog = true;
    },
    getStatistics() {
      this.loading = true;

      let body = {};
      if (this.filterDate == "custom") {
        this.quoteDate = {
          date1: moment(this.dateFrom).startOf("day").toISOString(),
          date2: moment(this.dateTo).endOf("day").toISOString(),
        };
        this.query1 = this.quoteDate;
        body = { ...body, quoteDate: this.quoteDate };
      }

      getAPI
        .post("/reports/quoted-client-agent", body)
        .then((res) => {
          this.loading = false;
          const response = res.data;
          this.statistics = response.filter((r) => r.fullname != "TOTAL");
          this.total = response.find((r) => r.fullname == "TOTAL");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    clearFilters() {
      this.dateFrom = null;
      this.dateTo = null;
      this.filterDate = "clear";

      this.getStatistics();
    },
    tryRequest() {
      this.interval = setInterval(this.getStatistics, 80000);
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    getStatusShow(value) {
      if (value) {
        const st = PolicyStatus[value];
        return st;
      }

      return "-";
    },

    colorStatus(value) {
      if (value != null && value != undefined) {
        switch (value) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "POLICY_NEW":
            return "#80FF00";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "POLICY_BLACKLIST":
            return "#270E41";
          default:
            return "#000";
        }
      }
      return "#000";
    },
  },
  mounted() {
    this.mutDisplaylogo(true);
    this.tryRequest();
    this.getStatistics();
  },
  destroyed() {
    this.mutDisplaylogo(false);
    clearInterval(this.interval);
  },
};
</script>
<style lang="scss" scoped>
.logo {
  z-index: 999;
}
</style>
